import React from 'react'
import { IEventNotification } from '../../../types/eventNotifications'
import { Divider, Typography } from '@mui/material'
import { formatTimestamp } from '../../../utility/formatDate'
type PROPS={
    eventNotification:IEventNotification
    showBottomBorder?:boolean
}
export const EventNotificationRow:React.FC<PROPS> = ({eventNotification,showBottomBorder}) => {
    return <div className='flex items-center justify-start max-h-[60px] py-2 max-w-[99%] mb-2' style={{borderBottom:showBottomBorder?'1px solid lightgrey':undefined}}>
        <p className='text-base p-0 font-bold max-h-[80px] w-[150px]'>{formatTimestamp(eventNotification.ReferenceDate)}</p>
        {/* <p className='text-xl m-1 p-0 font-bold'>{formatTimestamp("2020-01-01T09:00")}</p> */}
        <Divider />
        <Typography className='!text-lg p-0 min-w-[300px] max-h-[80px]'>{eventNotification.Description}</Typography>
    </div>
}