import { Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../utility/useIsMobile'
type PROPS = {
    text: string
    extraText?: string
    extraTextClassNames?: string
    icon?: React.ReactElement
    noBorder?: boolean
}
export const DetailsTitle: React.FC<PROPS> = ({ text, extraText, extraTextClassNames, icon, noBorder }) => {
    const { isMobile } = useIsMobile();
    if (extraText) {
        return <div className='flex items-baseline justify-between mb-1 pr-2 relative'>
            <Typography
                fontSize={isMobile ? "1.5rem" : "2rem"}
                style={{ borderBottom: noBorder ? 'none' : '1px solid lightgrey', flexGrow: 1 }} >
                {text}
                {icon}
            </Typography>
            <Typography
                fontSize={isMobile ? "1.5rem" : "2rem"}
                style={{ borderBottom: noBorder ? 'none' : '1px solid lightgrey' }}
                className={extraTextClassNames ?? ''}
            >{extraText}</Typography>
        </div>
    }
    return <Typography fontSize={isMobile ? "1.5rem" : "2.25rem"} style={{ borderBottom: '1px solid lightgrey', width: '100%', marginBottom: 3 }} variant="h3">
        {text}
        {icon}
    </Typography>
}