import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../utility/useIsMobile'
export const DetailsLabel: React.FC<{ label: string,bold?:boolean,align?: "center" | "right" | "left" | "inherit" | "justify" }> = ({ label,bold,align }) => {
    const {isMobile} = useIsMobile();
    return <Grid item xs={4} sm={4} className='min-h-[20px] border-r border-r-gray-400'>
        <Typography 
        variant='body1' 
        align={"left"}
        className={`${isMobile ? "!text-base":"!text-2xl"} ${bold ? "!font-bold" : ""} `}>
            {label}:
        </Typography>
    </Grid>
}