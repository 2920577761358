import React from 'react'
import { ISortableHeader } from '../../../../types/general'
import { IconButton, Typography } from '@mui/material'
import { useHeaderCell } from '../../../HeaderCell/hooks/useHeaderCell'
import { PaginationEnum } from '../../../../store/pager'
import { IoNavigateSharp } from 'react-icons/io5'
import { FcViewDetails } from "react-icons/fc";
import { PiListDashesFill } from "react-icons/pi";
import { useNavigate } from 'react-router-dom'
import { intesa_green } from '../../../../../../themes/themes'
import { RiFileList3Fill } from 'react-icons/ri'
import { useUserContext } from '../../../../../../store/hooks/useUserContext'
import { ICustomClaims } from '../../../../services/user'
import { AssignmentForceClosureButton } from '../../../AssignmentForceClosureButton/AssignmentForceClosureButton'
type PROPS = {
    header: ISortableHeader<any>
    x: any
    isLoading: boolean,
    enumStoreKey: PaginationEnum
}
export const statusClassNames = (status: string) => {
    let _ = status.toLowerCase();
    switch (_) {
        case "active": return "!bg-gray-100 !text-black  !border !border-red-400 !p-1 !rounded !shadow leading-8 truncate"
        case "resolved": return "!bg-gray-100 !text-black  !border !p-1 !rounded !shadow leading-8 truncate"
        case "viewed": return "!bg-gray-200 !text-black !font-bold !border !p-1 !rounded !shadow leading-8 truncate"
        case "new": return "!bg-intesa-green !text-white !font-bold !border !p-1 !rounded !shadow align-baseline leading-8 truncate"
        default: return "text-black truncate"
    }
}
export const ODataGridRowCell: React.FC<PROPS> = ({ header, x, isLoading, enumStoreKey }) => {
    const {
        setHeaderFilter
    } = useHeaderCell({
        header, enumStoreKey
    })
    const navigate = useNavigate();
    const getHeaderValue = (header: ISortableHeader<any>) => {
        if (header.selector) {
            console.log(header.selector(x))
            return header.selector(x)
        }
        if (header.text) {
            return header.text(x)
        }
        return undefined;
    }
    const isStatusHeader = ["Stato"].includes(header.label);
    if (header.text)
        return <div className='flex items-center m-0 p-0 w-[98%] !min-h-[30px]'>
            <Typography
                variant="body1"
                onContextMenu={(e) => {
                    e.preventDefault();
                    setHeaderFilter(header, getHeaderValue(header))
                }}
                align="center"
                className={`${isStatusHeader ? statusClassNames(x.Status) : ''} w-[100%] text-center px-1`}
            >
                {x ? header.text(x) : ""}
            </Typography>
        </div>
    if (header.element)
        return x ? <>{header.element(x)}</> : null
    return null;
}