import React from 'react'
import { LinearProgress } from '@mui/material'
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsLabel } from '../DetailsLabel/DetailsLabel'
import { DetailsValue } from '../DetailsValue/DetailsValue'
import { IExtendedAlert, } from '../../types/alerts'
import { getExtendedAlert } from '../../services/alerts'
import { globalContext } from '../../store/globalStore'
import { APIError } from '../../types/general'
import { assignments_known_urls } from '../../types/userStore'
import { DetailsTitle } from '../DetailsTitle/DetailsTitle'
import { DetailsRow } from '../DetailsRow/DetailsRow'
import { Spacer } from '../Spacer/Spacer'
import { formatNumericValue } from '../../utility/formats'
import { DetailNavigatorIcon } from '../DetailNavigatorIcon/DetailNavigatorIcon'
import { DetailDescription } from '../DetailsRow/DetailDescription/DetailDescription'
import { DetailsFocusPaper } from '../DetailsRow/DetailsFocusPaper/DetailsFocusPaper'
import { formatDate } from '../../utility/formatDate'
import { intesa_green } from '../../../../themes/themes'
import { useUserContext } from '../../../../store/hooks/useUserContext'
import { hasPermission } from '../../../../store/hooks/useUserStore'
import { known_actions } from '../../../../types/userStore'
import { ExpandableContainer } from '../ExpandableContainer/ExpandableContainer'
import { statusClassNames } from '../ODataGrid/ODataGridRow/ODataGridRowCell/ODataGridRowCell'
type Props = {
    id: number,
    showContainerOnLoading?: boolean
}
export const ExtendedAlertDetailExpandableContainer: React.FC<Props> = ({ id, showContainerOnLoading }) => {
    const { authenticatedCall, userClaims } = useUserContext();
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [alert, setAlert] = React.useState<IExtendedAlert>()
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            getExtendedAlert(id)
                .then((res) => setAlert(res))
                .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                .finally(() => setIsLoading(false))
        }
        authenticatedCall(_load);
    }, [authenticatedCall, globalDispatch, id])
    if (!hasPermission(userClaims, known_actions.view_events)) {
        return null;
    }
    if (!alert && !showContainerOnLoading) {
        return null;
    }
    return <>
        {isLoading && <LinearProgress style={{ color: intesa_green, marginTop: 20, width: 100, height: 10 }} />}
        <ExpandableContainer textClassNames='mt-2' iconPosition='beforeText' text="Dettagli">
            <DetailsTitle text="Alert"
                extraTextClassNames={statusClassNames(formatDate(alert?.CloseDate) ? "resolved" : "active")}
                extraText={`${formatDate(alert?.CloseDate) ? "Disattivo" : "Attivo"}`} 
                icon={<DetailNavigatorIcon url={assignments_known_urls.alert_event_detail} id={id} />}    
            />
            
            {alert ? <>
                <DetailsLabelValueContainer topBorder fullWidth>
                    <DetailsLabel bold label="Descrizione" />
                    <DetailsValue value={alert.Metric.HierarchyName} />
                </DetailsLabelValueContainer>
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel bold label="Valore Soglia" />
                    <DetailsValue noFormat value={`${formatNumericValue(alert.TargetValue ?? 0)} ${alert.Metric.UoM}`} />
                </DetailsLabelValueContainer>
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel bold label="Valore Corrente" />
                    <DetailsValue noFormat value={`${formatNumericValue(alert.Value ?? 0)} ${alert.Metric.UoM}`} />
                </DetailsLabelValueContainer>
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label="Data apertura" />
                    <DetailsValue value={alert.OpenDate} />
                </DetailsLabelValueContainer>
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel bold label="Data chiusura" />
                    <DetailsValue value={alert.CloseDate} />
                </DetailsLabelValueContainer>

                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label="ID Metrica" />
                    <DetailsValue value={alert.Metric.MetricGuid} />
                </DetailsLabelValueContainer>
                <DetailsLabelValueContainer roundedDown fullWidth>
                    <DetailsLabel bold label="ID Alert" />
                    <DetailsValue value={alert.Id} />
                </DetailsLabelValueContainer>


            </> : <></>
            }
        </ExpandableContainer >

        {/* </DetailsContainer> */}
    </>
}