import React from 'react'
import { Page } from '../../components/Page/Page'
import { useQuery } from '../../utility/useQuery';
import { AssignmentDetail } from '../../components/AssignmentDetail/AssignmentDetail';
import { IconButton, Typography } from '@mui/material';
import { viewAssignment } from '../../services/assignments';
import { TAssignment } from '../../types/assignments';
import { paginationContext } from '../../store/pager';
import { NavigationStateKeysEnum, navigationContext } from '../../store/navigationStore';
import { ISidebarOptions } from '../../types/panels';
import { AssignmentsListNavigator } from '../../components/AssignmentsListNavigator/AssignmentsListNavigator';
import { DisposableSidebar } from '../../components/Page/Sidebar/DisposableSidebar/DisposableSidebar';
import { topbarHeight } from '../../components/Page/Topbar/Topbar';
import { useSidebars } from '../../store/hooks/useSidebars';
import { APIError } from '../../types/general';
import { globalContext } from '../../store/globalStore';
import { useIsMobile } from '../../utility/useIsMobile';
import { DisposableSidebarIcon } from '../../components/Page/Sidebar/DisposableSidebar/DisposableSidebarIcon/DisposableSidebarIcon';
import { useUserContext } from '../../../../store/hooks/useUserContext';
import { AssignmentDetailToolbar } from '../../components/AssignmentDetailToolbar/AssignmentDetailToolbar';
export const AssignmentDetailPage: React.FC = () => {
    const { bottombarHeight, sidebarWidth } = useIsMobile();
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const { items: assignments } = paginationState.pagination.ASSIGNMENTS;
    const { isLoaded } = paginationState;
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const { accessToken } = useUserContext();
    const { defaultBottombarHeight, isMobile } = useSidebars();
    const query = useQuery();
    const { state, dispatch } = React.useContext(navigationContext)
    const setSidebarOptions = (opt: ISidebarOptions) => dispatch({ type: "SET_SIDEBAR_OPTIONS", options: opt })
    const defaultSidebarOptions: ISidebarOptions = {
        isOpen: false,
        isModal: false,
        isClosable: true,
        width: "xl",
        position: "right",
        transitionMovementType: "none",
        transitionResizeType: "width",
        childrens: <AssignmentsListNavigator />,
        offset: {
            x: 0,
            y: topbarHeight,
        },
    }
    React.useEffect(() => {
        setSidebarOptions(defaultSidebarOptions);
    }, [])
    const [isLoading, setIsLoading] = React.useState(false);
    const [assignment, setAssignment] = React.useState<TAssignment>(assignments.find(a => a.Id === parseInt(query.get("id") ?? '-1')))
    React.useEffect(() => setAssignment(assignments.find(a => a.Id === parseInt(query.get("id") ?? '-1'))), [assignments])
    React.useEffect(() => {
        return () => { paginationDispatch({ type: "SET_ISLOADED", payload: { ...isLoaded, ASSIGNMENT_DETAILS: false } }); }
    }, [])
    React.useEffect(() => {
        const _load = async () => {
            if (accessToken) {
                setIsLoading(true);
                viewAssignment(parseInt(query.get("id") ?? ""))
                    .then((result: TAssignment) => {
                        setAssignment(result)
                    })
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            }
        }
        _load();
    }, [query, accessToken, isLoaded])
    return <Page>
        
        <DisposableSidebar height={isMobile ? window.innerHeight - topbarHeight - defaultBottombarHeight : window.innerHeight - topbarHeight} />
        {assignment ?
            <div className="flex flex-col items-center" style={{ padding: 5, width: `calc(100vw - ${sidebarWidth}px)`, height: `calc(100% - ${bottombarHeight/2}px)`, overflowY: 'auto', overflowX: 'hidden' }}>
                <AssignmentDetailToolbar fullWidth={isMobile ? true : false} assignment={assignment}/>
                <div className='mt-4' />
                <AssignmentDetail showDetails fullWidth={isMobile ? true : false} isLoading={isLoading} assignment={assignment} />                
            </div>
            :
            <div className="flex flex-col w-[100%] h-[100%] items-center mt-10">
                <Typography>Assegnazione non trovata</Typography>
            </div>
        }
        {!state[NavigationStateKeysEnum.SIDEBAR].isOpen &&
            <IconButton
                style={{
                    position: 'fixed',
                    right: 5,
                    top: isMobile ? 'unset' : 'calc(50vh - 20px)',
                    bottom: isMobile ? defaultBottombarHeight : 'unset',
                    height: 30,
                    width: 30,
                    padding: 0,
                    // marginLeft:'auto'
                }}
                onClick={() => setSidebarOptions({ ...defaultSidebarOptions, isOpen: true })}>
                <DisposableSidebarIcon direction='left' style={{ width: 30, height: 30 }} />
            </IconButton>}
    </Page>

}