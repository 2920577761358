import { Button, Dialog, IconButton, Snackbar, Typography } from "@mui/material";
import React from "react";
import { ICustomClaims } from "../../services/user";
import { useUserContext } from "../../../../store/hooks/useUserContext";
import { TAssignment } from "../../types/assignments";
import { paginationContext, PaginationEnum } from "../../store/pager";
import { resolveAssignment } from "../../services/assignments";
import { IoStopOutline } from "react-icons/io5";
type Props = {
    assignment: TAssignment
}
export const AssignmentForceClosureButton: React.FC<Props> = ({ assignment }) => {
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const { userClaims } = useUserContext();
    const sbhAdminClaim: ICustomClaims = { ProductId: "sbh", RuoloId: 'admin' }
    const isAdmin = userClaims.find((x) => x.ProductId === sbhAdminClaim.ProductId && x.RuoloId === sbhAdminClaim.RuoloId) !== undefined;
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const handleAdminBtnClick = (e:any) => {
        e.stopPropagation();
        setIsLoading(true);
        const adminClosureReason = "Chiuso da admin"
        resolveAssignment(assignment.Id, adminClosureReason)
            .then((res) => {
                paginationDispatch({
                    type: "SET_PAGINATION", payload: {
                        key: PaginationEnum.ASSIGNMENTS,
                        pagination: {
                            ...paginationState.pagination.ASSIGNMENTS,
                            items: paginationState.pagination.ASSIGNMENTS.items.map((a) => a.Id === assignment.Id ? res : a)
                        }
                    }
                });
                setOpenSnackBar(true)
                setOpenConfirm(false)
            })
            .finally(() => setIsLoading(false))
    }
    const handleCloseDialog = (e:any)=>{
        e.stopPropagation();
        setOpenConfirm(false)
    }
    if (!isAdmin) return null;
    return <><Dialog open={openConfirm} onClose={handleCloseDialog}>
        <div onClick={(e)=>e.stopPropagation()} onDoubleClick={(e)=>e.stopPropagation()} className="flex flex-col min-h-[150px] p-2">
            <Typography>Sei sicuro di voler forzare la chiusura?</Typography>
            <div className="mt-auto" />
            <div className="flex w-[100%] justify-between">
                <Button variant="outlined" onClick={handleCloseDialog}>Annulla</Button>
                <Button variant="contained" onClick={handleAdminBtnClick}>Conferma</Button>
            </div>
        </div>
    </Dialog>
        <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={() => setOpenSnackBar(false)}
            message={`Segnalazione risolta correttamente`}
        />
        <IconButton 
        style={{ zIndex: 999999, padding: 0, margin: 0, width:30,height:30,borderRadius:2 }} 
        className="hover:!bg-gray-100"
        onClick={(e) => {
            e.stopPropagation();
            setOpenConfirm(true)
        }}>
            <IoStopOutline style={{width:20,height:20}}/>
        </IconButton>
    </>
}