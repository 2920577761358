import React from 'react'
import { globalContext } from '../../store/globalStore'
import { IEventNotification } from '../../types/eventNotifications'
import { getPagedEventNotifications } from '../../services/eventsNotification'
import { IColumnFilter, IColumnSorting } from '../../types/odata'
import { EticketSourceTypeEnum } from '../../types/assignments'
import { APIError } from '../../types/general'
import { TopBarLoading } from '../TopBarLoading/TopBarLoading'
import { EventNotificationRow } from './EventNotificationRow/EventNotificationRow'
import { useSidebars } from '../../store/hooks/useSidebars'
import { useUserContext } from '../../../../store/hooks/useUserContext'
type PROPS = {
    eventId: number
    sourceType: EticketSourceTypeEnum
}
export const EventNotificationsHistory: React.FC<PROPS> = ({ eventId, sourceType }) => {
    const {
        sidebarIsOpen,
        state: sidebarsState,
    } = useSidebars();
    const { authenticatedCall } = useUserContext();
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [eventNotifications, setEventNotifications] = React.useState<IEventNotification[]>()
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            const sortings: IColumnSorting<IEventNotification>[] = [
                {
                    key: "Id",
                    direction: "desc",
                    innerClass: ""
                }
            ]
            const filters: IColumnFilter<IEventNotification>[] = [
                {
                    columnName: "ExternalEventId",
                    operator: 'eq',
                    value: eventId,
                    valueType: "number"
                },
                {
                    columnName: "SourceType",
                    operator: 'eq',
                    value: sourceType,
                    valueType: "enum"
                },

            ]
            getPagedEventNotifications(0, 200, sortings, filters)
                .then((res) => setEventNotifications(res.value))
                .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                .finally(() => setIsLoading(false))
        }
        authenticatedCall(_load);
    }, [authenticatedCall, globalDispatch, eventId, sourceType])
    if (isLoading) {
        return <TopBarLoading />
    }
    if (!eventNotifications || eventNotifications.length === 0) return <p>Non è ancora stata inviata alcuna notifica</p>;
    return <div
        style={{
            width: `calc(100vw - 25px - ${sidebarIsOpen ? sidebarsState.sidebarWidth : 0}px)`,
            // marginLeft: -5, 
            overflowX: 'auto',
            overflowY:'hidden',
            display: 'flex',
            flexDirection: 'column'
        }}
    >
        <p className="text-3xl font-bold p-0 m-0 mt-2 ml-2">Storico</p>
        <div style={{ width: '100%' }}>
            <div className="flex flex-col border border-solid rounded p-2">
                {eventNotifications.map((eventNotification,i) => <EventNotificationRow showBottomBorder={i<eventNotifications.length - 1} eventNotification={eventNotification} />)}
            </div>
        </div>
    </div>
}