import React from "react"
import { formatDate } from "../../utility/formatDate";
import { ISortableHeader } from "../../types/general";
import { AssignmentStatusEnum, EticketSourceTypeEnum, TAssignment } from "../../types/assignments";
import { CumulativeStatusEnum, ETicket } from "../../types/etickets";
import { PaginationEnum, paginationContext } from "../pager";
import { IInputEvent } from "../../types/eventNotifications";
import { UnlockAssignmentBtn } from "../../components/AssignmentsGrid/AssignmentRow/UnlockAssignmentBtn/UnlockAssignmentBtn";
import { HeaderFilterProps } from "../../components/HeaderCell/types";
import { TagsHeaderFilterCell } from "../../components/HeaderCell/HeaderFilterCell/TagsHeaderFilterCell/TagsHeaderFilterCell";
import { TagsElementCell } from "../../components/AssignmentsGrid/AssignmentRow/TagsElementCell/TagsElementCell";
import { assignments_known_urls } from "../../types/userStore";
import { ClosureReasonFilterCell } from "../../components/HeaderCell/HeaderFilterCell/ClosureReasonFilterCell/ClosureReasonFilterCell";
import { AssignmentForceClosureButton } from "../../components/AssignmentForceClosureButton/AssignmentForceClosureButton";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import viewDetailIcon from '../../images/viewDetailIcon.png'
export const localStorageResizesItemKey = "header-resizes"
export const localStorageHidesItemKey = "header-hides"

export const Status_Mapper: { [key: string]: string } = {
    "new": "Nuovo",
    "open": "Aperto",
    "closed": "Chiuso",
    "standby": "Standby",
    "waitingopening": "In apertura",
    "unknown": "Sconosciuto",
    "notrequested": "Non richiesto",
    "requested": "Richiesto",
    "completed": "Completato",
    "error": "error",
    "viewed": "Visualizzato",
    "assigned": "Assegnato",
    "resolved": "Risolto"
}
export const SourceType_Mapper: { [key in EticketSourceTypeEnum]: string } = {
    [EticketSourceTypeEnum.Metric]: "Metrica",
    [EticketSourceTypeEnum.SuperMetric]: "SuperMetrica",
}
export const useHeaders = () => {
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const { sortings: assignmentSortings } = paginationState.pagination.ASSIGNMENTS;
    const { sortings: ticketsSortings } = paginationState.pagination.TICKETS;
    const { sortings: datamonitorSortings } = paginationState.pagination.INPUT_EVENTS;
    const defaultAssigmentsHeaderSort = (e: React.MouseEvent<HTMLDivElement>, key: keyof TAssignment, innerClass: string, newDirection: "asc" | "desc") => {
        const keyIsNotSorted = assignmentSortings.find((s) => s.key === key) === undefined;
        // const newDirection: "asc" | "desc" = keyIsNotSorted ? "desc" : assignmentSortings.find((s) => s.key === key)?.direction === "asc" ? "desc" : "asc";
        if (e.shiftKey) {
            const newValue = { key, direction: newDirection, innerClass };
            paginationDispatch({
                type: "SET_SORTINGS",
                payload: { key: PaginationEnum.ASSIGNMENTS, sortings: keyIsNotSorted ? [...assignmentSortings, newValue] : assignmentSortings.map((s) => s.key === key ? newValue : s) }
            })
        } else {
            paginationDispatch({
                type: "SET_SORTINGS",
                payload: { key: PaginationEnum.ASSIGNMENTS, sortings: [{ key, direction: newDirection, innerClass }] }
            })
        }
    }
    const defaultTicketsHeaderSort = (e: React.MouseEvent<HTMLDivElement>, key: keyof ETicket, innerClass: string) => {
        const keyIsNotSorted = ticketsSortings.find((s) => s.key === key) === undefined;
        const newDirection: "asc" | "desc" = keyIsNotSorted ? "desc" : ticketsSortings.find((s) => s.key === key)?.direction === "asc" ? "desc" : "asc";
        if (e.shiftKey) {
            const newValue = { key, direction: newDirection, innerClass };
            paginationDispatch({
                type: "SET_SORTINGS",
                payload: { key: PaginationEnum.TICKETS, sortings: keyIsNotSorted ? [...ticketsSortings, newValue] : ticketsSortings.map((s) => s.key === key ? newValue : s) }
            })
        } else {
            paginationDispatch({
                type: "SET_SORTINGS",
                payload: { key: PaginationEnum.TICKETS, sortings: [{ key, direction: newDirection, innerClass }] }
            })
        }
    }
    const defaultDataMonitorHeaderSort = (e: React.MouseEvent<HTMLDivElement>, key: keyof IInputEvent, innerClass: string) => {
        const keyIsNotSorted = datamonitorSortings.find((s) => s.key === key) === undefined;
        const newDirection: "asc" | "desc" = keyIsNotSorted ? "desc" : datamonitorSortings.find((s) => s.key === key)?.direction === "asc" ? "desc" : "asc";
        if (e.shiftKey) {
            const newValue = { key, direction: newDirection, innerClass };
            paginationDispatch({
                type: "SET_SORTINGS",
                payload: { key: PaginationEnum.INPUT_EVENTS, sortings: keyIsNotSorted ? [...datamonitorSortings, newValue] : datamonitorSortings.map((s) => s.key === key ? newValue : s) }
            })
        } else {
            paginationDispatch({
                type: "SET_SORTINGS",
                payload: { key: PaginationEnum.INPUT_EVENTS, sortings: [{ key, direction: newDirection, innerClass }] }
            })
        }
    }
    const tryGetHeaderStoredValue = (storageKey: string, enumkey: PaginationEnum, key: string, defaultValue: any) => {
        const storageItem = localStorage.getItem(`${enumkey}-${storageKey}`)
        if (!storageItem) {
            return defaultValue
        }
        return JSON.parse(storageItem)[`${enumkey}-${key}`] ?? defaultValue
    }
    const navigate = useNavigate();
    const [assignmentHeaders, setAssignmentHeaders] = React.useState<ISortableHeader<TAssignment>[]>([
        {
            id: "Id",
            label: "ID",
            width: '4.5%',
            // text: (a: TAssignment) => a.Id.toString(),
            element: (a: TAssignment) => <div className="flex flex-col justify-stretch w-[100%]">
                <p className="text-center w-[100%] text-lg">{a.Id}</p>
                <div className="flex justify-between w-[100%] px-2 items-baseline">
                    <IconButton
                        style={{ padding: 0, margin: 0, width: 30, height: 30,borderRadius:2 }}
                        className="hover:!bg-gray-100"
                        onClick={() => {
                            navigate(`${assignments_known_urls.assignment_detail}?id=${a.Id}`,)
                        }}
                    >
                        <img src={viewDetailIcon} style={{width:20,height:20}} />
                    </IconButton>
                    <AssignmentForceClosureButton assignment={a} />
                </div>
            </div>,
            selector: (a: TAssignment) => a.Id,
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            valueType: "number",
            defaultFilterOperator: 'eq',
            navigationUrl: (a: TAssignment) => `${assignments_known_urls.assignment_detail}?id=${a.Id}`,
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "Id", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "Id", 0)
        },
        {
            id: "Tags",
            hideFilterIcon: true,
            nestedColumn: "Tag",
            label: "Tags",
            width: '10%',
            element: (a: TAssignment) => <TagsElementCell assignment={a} />,
            onSort: (e, key) => { },
            clickable: false,
            customFilterCell: (props: HeaderFilterProps) => <TagsHeaderFilterCell {...props} />,
            valueType: "string",
            defaultFilterOperator: 'any',
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "Tags", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "Tags", 0)
        },
        {
            id: "Polo",
            label: "Polo",
            width: '5%',
            text: (a: TAssignment) => a.Polo?.toString(),
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "Polo", false),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "Polo", 0)
        },
        {
            id: "BuildingName",
            label: "Edificio",
            width: '15%',
            // text: (a: TAssignment) => a.BuildingName?.toString(),
            element: (a: TAssignment) => <div className="flex flex-col">
                <p className="text-center m-0 px-[5%] py-0 border-b border-b-gray w-[100%]">{a.Polo}</p>
                <p className="text-center m-0 px-[5%] py-0 w-[100%]">{a.BuildingName}</p>
                {/* <p className="text-center m-0 px-[5%] py-0 w-[90%]">{a.CRImmobile}</p> */}
            </div>,
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "BuildingName", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "BuildingName", 0),
            linkedColumns: ["Polo",
                // "CRImmobile"
            ]
        },
        {
            id: "Description",
            label: "Descrizione",
            width: '20%',
            text: (a: TAssignment) => a.Description?.toString(),
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "Description", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "Description", 0)
        },
        {
            id: "Status",
            label: "Stato",
            // hideFilterIcon:true,
            width: '5%',
            selector: (a: TAssignment) => a.Status.toString(),
            text: (a: TAssignment) => Status_Mapper[a.Status.toString().toLowerCase()],
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            valueType: "enum",
            filterOptions: Object.entries(AssignmentStatusEnum).map((e) => ({ label: Status_Mapper[e[1].toLowerCase()], value: e[0] })),
            defaultFilterOperator: 'eq',
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "Status", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "Status", 0)
        },
        {
            id: "OwnerEmail",
            label: "Assegnata a",
            width: '22%',
            text: (a: TAssignment) => a.OwnerEmail,
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "OwnerEmail", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "OwnerEmail", 0)
        },
        {
            id: "OpeningTime",
            label: "Data Creazione",
            width: '10%',
            selector: (a: TAssignment) => ({ startDate: new Date(a.OpeningTime).setHours(0, 0, 0), endDate: new Date(a.OpeningTime).setHours(23, 59, 59) }),
            hideFilterIcon: true,
            text: (a: TAssignment) => formatDate(a.OpeningTime.toString()),
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            defaultFilterOperator: 'ge-le',
            valueType: "daterange",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "OpeningTime", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "OpeningTime", 0)
        },
        {
            id: "ClosureReason",
            label: "Motivo Chiusura",
            width: '10%',
            text: (a: TAssignment) => a.ClosureReason,
            customFilterCell: (props) => <ClosureReasonFilterCell {...props} />,
            onSort: (e, key, newDirection) => defaultAssigmentsHeaderSort(e, key, "", newDirection),
            clickable: true,
            defaultFilterOperator: 'contains',
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "ClosureReason", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "ClosureReason", 0)
        },
        // {
        //     id: "ExpirationTime",
        //     label: "Scadenza",
        //     width: '15%',
        //     element: (a: TAssignment) => formatDate(a.ExpirationTime.toString()),
        //     onSort: (e, key,newDirection) => defaultAssigmentsHeaderSort(e, key, "",newDirection),
        //     defaultFilterOperator:'ge-le',
        //     clickable: true,
        //     valueType: "daterange"
        // },
        {
            id: "UnlockColumn",
            label: "",
            width: '5%',
            element: (a: TAssignment) => <UnlockAssignmentBtn assignment={a} />,
            onSort: () => { },
            clickable: false,
            valueType: "string",
            hideFilter: true,
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.ASSIGNMENTS, "UnlockColumn", false),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.ASSIGNMENTS, "UnlockColumn", 0)
        }
    ])
    const [ticketHeaders, setTicketHeaders] = React.useState<ISortableHeader<ETicket>[]>([
        {
            id: "Id",
            label: "Id",
            width: '5%',
            element: (a: ETicket) => a.Id?.toString(),
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "string",
            defaultFilterOperator: 'eq',
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "Id", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "Id", 0)
        },
        {
            id: "Polo",
            label: "Polo",
            width: '10%',
            element: (a: ETicket) => a.Polo?.toString(),
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "Polo", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "Polo", 0)
        },
        {
            id: "BuildingName",
            label: "Edificio",
            width: '10%',
            element: (a: ETicket) => a.BuildingName?.toString(),
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "BuildingName", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "BuildingName", 0)
        },
        {
            id: "OpeningTime",
            label: "Data Apertura",
            selector: (a: ETicket) => a.OpeningTime ? ({ startDate: new Date(a.OpeningTime).setHours(0, 0, 0), endDate: new Date(a.OpeningTime).setHours(23, 59, 59) }) : null,
            width: '20%',
            element: (a: ETicket) => formatDate(a.OpeningTime),
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "daterange",
            defaultFilterOperator: 'ge-le',
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "OpeningTime", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "OpeningTime", 0)
        },
        {
            id: "Description",
            label: "Descrizione",
            width: '25%',
            element: (a: ETicket) => a.Description?.toString(),
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "Description", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "Description", 0)
        },
        {
            id: "CumulativeStatus",
            label: "Stato",
            width: '10%',
            element: (a: ETicket) => Status_Mapper[a.CumulativeStatus?.toString().toLowerCase()],
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "enum",
            defaultFilterOperator: 'eq',
            filterOptions: Object.entries(CumulativeStatusEnum).map((e) => ({ label: Status_Mapper[e[1].toLowerCase()], value: e[0] })),
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "CumulativeStatus", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "CumulativeStatus", 0)
        },
        {
            id: "CRImmobile",
            label: "CRImmobile",
            width: '10%',
            element: (a: ETicket) => a.CRImmobile,
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "CRImmobile", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "CRImmobile", 0)
        },
        {
            id: "CodiceAmbito",
            label: "CodiceAmbito",
            width: '10%',
            element: (a: ETicket) => a.CodiceAmbito,
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "CodiceAmbito", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "CodiceAmbito", 0)
        },
        {
            id: "NomeSpecifica",
            label: "NomeSpecifica",
            width: '15%',
            element: (a: ETicket) => a.NomeSpecifica,
            onSort: (e, key) => defaultTicketsHeaderSort(e, key, ""),
            clickable: true,
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.TICKETS, "NomeSpecifica", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.TICKETS, "NomeSpecifica", 0)
        },
    ])
    const sourceTypeRouteMap: { [key in EticketSourceTypeEnum]: string } = {
        [EticketSourceTypeEnum.Metric]: "alert-event-detail",
        [EticketSourceTypeEnum.SuperMetric]: "supermetric-event-detail",
    }
    const [inputEventsHeaders, setInputEventHeaders] = React.useState<ISortableHeader<IInputEvent>[]>([
        {
            id: "IsActive",
            label: "Attivo",
            width: '15%',
            text: (a: IInputEvent) => a.IsActive ? "Attivo" : "Disattivo",
            onSort: (e, key) => defaultDataMonitorHeaderSort(e, key, ""),
            clickable: true,
            valueType: "boolean",
            defaultFilterOperator: 'eq',
            navigationUrl: (a: IInputEvent) => `/assignments/${sourceTypeRouteMap[a.SourceType]}?id=${a.ExternalEventId}`,
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.INPUT_EVENTS, "IsActive", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.INPUT_EVENTS, "IsActive", 0)
        },
        {
            id: "ExternalEventId",
            label: "ID Evento",
            width: '15%',
            text: (a: IInputEvent) => a.ExternalEventId?.toString(),
            onSort: (e, key) => defaultDataMonitorHeaderSort(e, key, ""),
            clickable: true,
            valueType: "number",
            defaultFilterOperator: 'eq',
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.INPUT_EVENTS, "ExternalEventId", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.INPUT_EVENTS, "ExternalEventId", 0)
        },
        {
            id: "ExternalReferenceId",
            label: "ID Riferimento",
            width: '15%',
            text: (a: IInputEvent) => a.ExternalReferenceId?.toString(),
            onSort: (e, key) => defaultDataMonitorHeaderSort(e, key, ""),
            clickable: true,
            defaultFilterOperator: 'eq',
            valueType: "number",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.INPUT_EVENTS, "ExternalReferenceId", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.INPUT_EVENTS, "ExternalReferenceId", 0)
        },
        {
            id: "HierarchyName",
            label: "Descrizione",
            width: '25%',
            text: (a: IInputEvent) => a.HierarchyName?.toString(),
            onSort: (e, key) => defaultDataMonitorHeaderSort(e, key, ""),
            clickable: true,
            defaultFilterOperator: 'eq',
            valueType: "string",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.INPUT_EVENTS, "HierarchyName", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.INPUT_EVENTS, "HierarchyName", 0)
        },
        {
            id: "OpenDate",
            label: "Data Apertura",
            width: '15%',
            selector: (a: IInputEvent) => a.OpenDate ? ({ startDate: new Date(a.OpenDate).setHours(0, 0, 0), endDate: new Date(a.OpenDate).setHours(23, 59, 59) }) : null,
            text: (a: IInputEvent) => formatDate(a.OpenDate?.toString()),
            onSort: (e, key) => defaultDataMonitorHeaderSort(e, key, ""),
            clickable: true,
            defaultFilterOperator: 'ge-le',
            valueType: "daterange",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.INPUT_EVENTS, "OpenDate", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.INPUT_EVENTS, "OpenDate", 0)
        },
        {
            id: "CloseDate",
            label: "Data Chiusura",
            width: '15%',
            selector: (a: IInputEvent) => a.CloseDate ? ({ startDate: new Date(a.CloseDate).setHours(0, 0, 0), endDate: new Date(a.CloseDate).setHours(23, 59, 59) }) : null,
            text: (a: IInputEvent) => formatDate(a.CloseDate?.toString()),
            onSort: (e, key) => defaultDataMonitorHeaderSort(e, key, ""),
            clickable: true,
            defaultFilterOperator: 'ge-le',
            valueType: "daterange",
            isVisible: tryGetHeaderStoredValue(localStorageHidesItemKey, PaginationEnum.INPUT_EVENTS, "CloseDate", true),
            resize: tryGetHeaderStoredValue(localStorageResizesItemKey, PaginationEnum.INPUT_EVENTS, "CloseDate", 0)
        },
    ])

    const pageHeaders: { [key in PaginationEnum]: ISortableHeader<any>[] } = {
        [PaginationEnum.ASSIGNMENTS]: assignmentHeaders,
        [PaginationEnum.INPUT_EVENTS]: inputEventsHeaders,
        [PaginationEnum.TICKETS]: ticketHeaders,
    }

    return {
        pageHeaders, assignmentHeaders, ticketHeaders, inputEventsHeaders
        , setAssignmentHeaders, setTicketHeaders, setInputEventHeaders
    }
}