import { Grid } from '@mui/material'
import React from 'react'
export const DetailsLabelValueContainer: React.FC<{
    fullWidth?: boolean, 
    children: React.ReactNode, 
    roundedUp?: boolean, 
    roundedDown?: boolean, 
    topBorder?:boolean,
    topMargin?:boolean,
    direction?: "row" | "column" | "row-reverse",
    grow?:boolean
}> = ({ fullWidth, direction, children, roundedDown, roundedUp,grow,topMargin,topBorder }) => {
    const flexDirection = (direction) ? { flexDirection: direction } : {}
    return <Grid container
        className={`flex flex-row items-stretch justify-start 
            border-b border-x border-gray-400 px-2
            ${roundedDown ? 'rounded-b' : ''} 
            ${topMargin ? "mt-1" : ''} 
            ${topBorder ? "border-t border-t-gray-400" : ""}  
            ${roundedUp ? 'rounded-t' : ''}
            `}
        style={{
            maxWidth: (fullWidth) ? '100%' : grow ? undefined : '65%',
            width: (fullWidth) ? '100%' : grow ? undefined : 'calc(45% - 8px)',
            flexGrow: grow ? 1 : undefined,
            // width:'100%'
            ...flexDirection,
        }}>
        {children}
    </Grid>
}