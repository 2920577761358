import { IconButton } from '@mui/material';
import React from 'react'
import { IoNavigateSharp } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
type PROPS = {
    id: string | number,
    url: string,
    customStyles?: React.CSSProperties
}
export const DetailNavigatorIcon: React.FC<PROPS> = ({ id, url, customStyles }) => {
    const { pathname } = useLocation()
    const navigate = useNavigate();
    return <>
        {pathname !== url &&
            <IconButton
                style={{
                    // position: 'absolute', right: -5, top: -5, 
                    ...customStyles
                }}
                onClick={() => navigate(`${url}?id=${id}`)}
            >
                <IoNavigateSharp />
            </IconButton>}
    </>
}