import { LogLevel, Logger } from "@azure/msal-browser";
const clientId = process.env.REACT_APP_MSAL_CLIENTID ?? "";
const tenantId = process.env.REACT_APP_MSAL_TENANTID ?? "";
const authority = `https://login.microsoftonline.com/${tenantId}`;

const internal_hostname = "smartbp07.azurewebsites.net"
const internal_url = "https://smartbp07.azurewebsites.net"
let redirectUri = process.env.REACT_APP_MSAL_REDIRECTURI
if(window.location.hostname === internal_hostname){
  redirectUri = internal_url
}
export const msalConfig = {
  auth: {
    clientId,
    authority,
    redirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    piiLoggingEnabled: true,
    loggerOptions: {
      piiLoggingEnabled: true,
    },
  },
};

export const msalConfigGraph = {
  auth: {
    clientId,
    authority,
    redirectUri,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    piiLoggingEnabled: true,
    loggerOptions: {
      piiLoggingEnabled: true,
    },
  },
};

const pbiClientId = process.env.REACT_APP_MSAL_PBI_CLIENTID ?? "";
const pbiTenantId = process.env.REACT_APP_MSAL_TENANTID ?? "";
const pbiAuthority = `https://login.microsoftonline.com/${pbiTenantId}`;

export const msalConfigPbi = {
  auth: {
    clientId: pbiClientId,
    authority: pbiAuthority,
    redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    piiLoggingEnabled: true,
    loggerOptions: {
      logLevel: LogLevel.Warning,

      piiLoggingEnabled: true,
    },
  },
};

export const apiLoginRequest = {
  scopes: [process.env.REACT_APP_MSAL_REQUIRED_SCOPES!],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
