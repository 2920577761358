import React from 'react'
import { AssignmentStatusEnum, EticketSourceTypeEnum, TAssignment } from '../../types/assignments'
import { SiPowerbi } from "react-icons/si";
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsValue } from '../DetailsValue/DetailsValue'
import { DetailsLabel } from '../DetailsLabel/DetailsLabel'
import { Status_Mapper } from '../../store/hooks/useHeaders'
import { DetailsRow } from '../DetailsRow/DetailsRow'
import { DetailsTitle } from '../DetailsTitle/DetailsTitle'
import { assignments_known_urls } from '../../types/userStore'
import { DetailNavigatorIcon } from '../DetailNavigatorIcon/DetailNavigatorIcon'
import { Grid, LinearProgress, Typography } from '@mui/material'
import { useIsMobile } from '../../utility/useIsMobile'
import { DetailDescription } from '../DetailsRow/DetailDescription/DetailDescription'
import { AssignmentDetailCard } from './AssignmentDetailCard/AssignmentDetailCard'
import { statusClassNames } from '../ODataGrid/ODataGridRow/ODataGridRowCell/ODataGridRowCell'
import { intesa_green } from '../../../../themes/themes'
import { ExtendedAlertDetail } from '../AlertDetail/ExtendedAlertDetail'
import { ExtendedSuperMetricValueDetailExpandableContainer } from '../SuperMetricValueDetail/ExtendedSuperMetricValueDetailExpandableContainer'
import { IExtendedSuperMetricValue } from '../../types/etickets'
import { useUserContext } from '../../../../store/hooks/useUserContext'
import { globalContext } from '../../store/globalStore'
import { getSuperMetricValue } from '../../services/supermetric'
import { APIError } from '../../types/general'
import { IExtendedAlert } from '../../types/alerts'
import { getExtendedAlert } from '../../services/alerts'
import { Link } from 'react-router-dom'
import { stringCapitalize } from '../../../../utility/strings'
import { Spacer } from '../Spacer/Spacer';
import { ExtendedAlertDetailExpandableContainer } from '../AlertDetail/ExtendedAlertDetailExpandableContainer';
type Props = {
    isLoading: boolean
    assignment: TAssignment
    fullWidth?: boolean
    showDetails?:boolean
}
export const AssignmentDetail: React.FC<Props> = (props) => {
    const { isLoading, assignment, fullWidth } = props
    const { authenticatedCall, userClaims } = useUserContext();
    const [localIsLoading, setIsLoading] = React.useState(false);
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [superMetricValue, setSuperMetricValue] = React.useState<IExtendedSuperMetricValue>();
    const [alert, setAlert] = React.useState<IExtendedAlert>()
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            if (assignment.SourceType === EticketSourceTypeEnum.Metric) {
                getExtendedAlert(assignment.ExternalEventId)
                    .then((res) => setAlert(res))
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            } else {
                getSuperMetricValue(assignment.ExternalEventId)
                    .then((res) => setSuperMetricValue(res))
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            }
        }
        authenticatedCall(_load);
    }, [authenticatedCall, globalDispatch, assignment])
    const custom_values = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_value_link_key = "custom";
        const custom_report_link_key = "custom_link_report";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_value_link_key) && !key.toLowerCase().includes(custom_report_link_key)).map(([key, value]) => ({
            Name: stringCapitalize(key.substring(custom_value_link_key.length + 1).replaceAll("_", " ")),
            Value: `${value}`
        }))
    }, [superMetricValue])
    const reports_links = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_report_link_key = "custom_link_report";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_report_link_key)).map(([key, value]) => ({
            Name: key.substring(custom_report_link_key.length + 1).replaceAll("_", " "),
            Link: value
        }))
    }, [superMetricValue])
    const { isMobile } = useIsMobile();
    // if (isMobile) {
    //     return <AssignmentDetailCard {...props} />
    // }
    if (isLoading) {
        return <LinearProgress style={{ color: intesa_green, marginTop: 20, width: 100, height: 10 }} />
    }
    
    return <DetailsContainer fullWidth={fullWidth}>

        <DetailsTitle
            noBorder
            text="Assegnazione"
            extraText={`${Status_Mapper[assignment.Status.toString().toLowerCase()]}`}
            extraTextClassNames={statusClassNames(assignment.Status)}
            icon={<DetailNavigatorIcon url={assignments_known_urls.assignment_detail} id={assignment.Id} />}
        />
        <div className='p-1 rounded border shadow shadow-t-none'>
            <DetailsLabelValueContainer topMargin topBorder fullWidth roundedUp>
                <DetailsLabel align='left' bold label='Assegnata a' />
                <DetailsValue align='left' value={assignment.OwnerEmail} />
            </DetailsLabelValueContainer>

            {assignment.Status === AssignmentStatusEnum.Resolved &&
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel align='left' bold label="Motivo Chiusura" />
                    <DetailsValue align='left' value={assignment.ClosureReason} />
                </DetailsLabelValueContainer>
            }

            <DetailsLabelValueContainer fullWidth>
                <DetailsLabel bold label='Descrizione' />
                <DetailsValue align='left' value={assignment.Description} />
            </DetailsLabelValueContainer>
            {/* <div className='h-[10px]' /> */}
            <DetailsLabelValueContainer fullWidth>
                <DetailsLabel bold label='Data Creazione' />
                <DetailsValue align='left' value={assignment.OpeningTime} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Polo' />
                <DetailsValue align='left' value={assignment.Polo} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Presidio' />
                <DetailsValue  value={assignment.Presidio} />
            </DetailsLabelValueContainer>

            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Edificio' />
                <DetailsValue  value={assignment.BuildingName} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='CRI' />
                <DetailsValue  value={assignment.CRImmobile} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Valore' />
                {assignment.SourceType === EticketSourceTypeEnum.SuperMetric && superMetricValue &&
                    <DetailsValue  value={superMetricValue?.ActivationValue} />}
                {assignment.SourceType === EticketSourceTypeEnum.Metric && alert &&
                    <DetailsValue  value={alert.Value} />}
            </DetailsLabelValueContainer>

            {custom_values.map((custom_value) =>
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label={custom_value.Name} />
                    <DetailsValue  value={custom_value.Value} />
                </DetailsLabelValueContainer>
            )}
            <Spacer direction='y' spacing={2}/>
            {reports_links.length > 0 && <Typography fontWeight={"bold"} align='left' fontSize="1.25rem">Report collegati:</Typography>}
            <Grid container spacing={1} className='flex justify-start px-4'>
                {reports_links.map((r) => <Grid item xs={12} sm={6}>
                    <Link target="_blank" to={r.Link}>
                        <Typography align='left' className='hover:!bg-gray-200 flex items-center border border-slate-900 !px-4 !my-1' fontSize="1.25rem"><SiPowerbi className='mr-2' />{r.Name}</Typography>
                    </Link>
                </Grid>
                )}
            </Grid>

        </div>
        {props.showDetails && assignment.SourceType === EticketSourceTypeEnum.Metric && <ExtendedAlertDetailExpandableContainer id={assignment.ExternalEventId} />}
        {props.showDetails && assignment.SourceType === EticketSourceTypeEnum.SuperMetric && <ExtendedSuperMetricValueDetailExpandableContainer id={assignment.ExternalEventId} />}
    </DetailsContainer >
}