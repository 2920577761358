import React from 'react'
import { useSidebars } from '../../store/hooks/useSidebars'
type Props = {
    children?:React.ReactNode
    extraClassNames?:string
}
export const DetailsRow:React.FC<Props> = ({children,extraClassNames}) => {
    return <div className={`flex flex-row justify-between ${extraClassNames ?? ''}`}>
        {children}
    </div>
}